$primary-color: #FC3E6E;

.pmd-content .container-react {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 0;
  font-size: 14px;

  .status-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .status-details {
      flex-grow: 1;
      display: inline-block;

      span {
        font-size: 12px;
      }

      .refresh-btn {
        padding-left: 0;
        padding-right: 0;
        margin-right: 5px;
      }
    }

    .status-actions {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      flex-grow: 0;
      align-items: flex-end;
      justify-content: flex-end;

      > button {
        margin-left: 5px;
      }
    }
  }

  .btn-basic {
    color: #fff;
    background-color: transparent;
  }
  button.close {
    color: unset;
  }

  .modal .pmd-card-title-text {
    text-align: center;
  }

  .pmd-modal-list {
    h3 {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    .list-group-item {
      background-color: unset;
      padding: 0.5rem 1rem;
      font-size: 14px;

      &:before {
        content: '- '
      }
    }
  }

  .balances-table {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    tr.table-warning > td {
      background-color: rgba(255, 243, 205, 0.2) !important;
    }
    tr.table-success > td {
      background-color: #4BB543;
    }
    tr.table-danger > td {
      background-color: rgba(255, 221, 211, 0.4) !important;
    }
    tr.table-selected {
      border: 2px $primary-color solid;
    }
    td.table-affiliate-id {
      padding-left: 5px;
      padding-right: 5px;

      p {
        margin-bottom: 0;
        font-size: 11px;
      }

      & > div:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    .table-selectable > label {
      margin-bottom: 0;
    }
    th, td:not(.wire-info) {
      text-align: center;
    }
    .failed {
      font-style: italic;
    }
    .balance-disabled {
      text-decoration: line-through;
    }
    .receipt-link {
      display: block;
      padding: 3px;
      font-size: 12px;
      text-decoration: underline;
    }
    dl {
      margin-bottom: 0;
    }
  }

  .ho-link {
    text-decoration: underline;
  }

  .spinner > div {
    background-color: #ddd;
    margin-right: 1px;
  }

  .pmd-floating-action-btn.btn-primary {
    background-color: $primary-color;
  }
  .pmd-floating-action-btn i {
    color: white;
  }

  .panel {
    background-color: #1b2b42;

    &.panel-success > .panel-heading {
      background-color: #4BB543;
    }
    &.panel-danger > .panel-heading {
      background-color: rgba(255, 221, 211, 0.4) !important;
    }

    dd {
      margin-bottom: 2px;
    }

    .panel-actions {
      text-align: center;

      > button {
        margin-right: 5px;
        padding: 6px 12px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .status-row, h1.section-title {
    text-align: center;
  }
  .status-details span {
    display: block;
  }
  .panel-body {
    text-align: center;

    dd {
      margin-bottom: 5px !important;
    }
  }
}

// todo: move this somewhere better and imported cpc panel specific
.cpc-panel .pretty-json {
  pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
  .string { color: green; }
  .number { color: darkorange; }
  .boolean { color: blue; }
  .null { color: magenta; }
  .key { color: red; }
}
.cpc-panel {
  #active-links {
    text-align: center;
    tr > td, th {
      text-align: center;
    }
  }
  .link-disabled > td:not(:first-child) {
    color: #777;
  }

  .cpc-promoting {
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    margin-left: 3px;
    color: #3C7666;
  }
}
.pmd-card {
  border-radius: 5px;
}
